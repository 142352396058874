<template>
    <q-card-section class="no-padding">
        <q-btn
            outline
            color="primary"
            style="min-width: 84px; padding-left: 8px; padding-right: 8px"
            :disable="isDisabled"
            @click="onClick"
        >
            {{ price }}
        </q-btn>
        <!-- <q-btn v-ripple outline color="primary" icon="payment" @click="onClick" /> -->
        <q-dialog v-model="prompt">
            <q-card style="min-width: 300px">
                <q-form ref="myForm">
                    <q-card-section>
                        <!-- <div class="text-h6"></div> -->
                        <div class="text-subtitle">
                            Вы оформляете заказ на приобретение услуги
                            {{ place }}
                            {{ service_group }} ({{ age_type }}) /
                            {{ visiting_form }}, {{ price }} руб.
                        </div>
                    </q-card-section>

                    <q-card-section class="q-pt-none q-pb-none q-pb-none">
                        <q-select
                            v-if="visiting_form_id == 2"
                            class="q-pb-lg"
                            outlined
                            v-model="period"
                            :options="selectOption"
                            label="Выберите месяц"
                            autofocus
                            emit-value
                            map-options
                            :rules="[(val) => !!val || 'Обязательное поле', isPeriodValidate]"
                        />
                        <q-input
                            outlined
                            class="q-pb-lg"
                            ref="clientidRef"
                            v-model="clientid"
                            name="clientid"
                            label="Фамилия Имя Отчество"
                            :rules="[(val) => !!val || 'Обязательное поле']"
                        />
                        <div class="text-caption text-italic">
                            на указанный адрес будет направлен чек об оплате
                        </div>
                        <q-input
                            outlined
                            ref="clientEmailRef"
                            class="q-pb-lg"
                            v-model="client_email"
                            name="client_email"
                            label="Адрес электронной почты"
                            :rules="[
                                (val) => !!val || 'Обязательное поле',
                                isValidEmail,
                            ]"
                        />
                        <q-input
                            outlined
                            ref="clientPhoneRef"
                            class="q-pb-lg"
                            v-model="client_phone"
                            name="client_phone"
                            label="Телефон"
                            mask="+7 (###) ### - ####"
                            fill-mask
                            :rules="[(val) => !!val || 'Обязательное поле']"
                        />
                        <!-- <q-input
                            outlined
                            ref="clientPinRef"
                            class="q-pb-lg"
                            v-model="pin"
                            name="pin"
                            label="ПИН"
                            :rules="[(val) => !!val || 'Обязательное поле']"
                        /> -->
                    </q-card-section>
                    <q-card-section class="q-pt-none q-pb-none">
                        Нажимая на кнопку «Оплатить онлайн», я даю
                        <router-link
                            class="my-link"
                            to="/approved-personal-data"
                            target="_blank"
                        >
                            согласие на обработку персональных данных
                        </router-link>
                        и подтверждаю ознакомление с
                        <a
                            class="my-link"
                            href="https://urengoy-dobycha.gazprom.ru/about/politika-obrabotki-personalny/"
                            target="_blank"
                        >
                            Политикой обработки персональных данных в ООО
                            «Газпром добыча Уренгой»</a
                        >, а также соглашаюсь с Условиями продажи.
                    </q-card-section>
                    <q-card-actions :align="'right'" class="text-primary">
                        <q-btn flat label="Отменить" v-close-popup />
                        <q-btn flat label="Оплатить онлайн" @click="onSBP" />
                        <q-btn
                            v-if="!isProd"
                            flat
                            label="Тестовая оплата"
                            @click="onTestPay"
                        />
                        <!-- <q-btn flat label="Оплата по карте" @click="onCard" /> -->
                    </q-card-actions>
                </q-form>
            </q-card>
        </q-dialog>
    </q-card-section>
</template>

<script>
import { ref, computed } from 'vue'
import { useQuasar } from 'quasar'
import Repository from '@/app/repositories/repositories.js'
import { initConfigStore } from '@/app/helpers'
import userDateSelectOption from '../hooks/dateSelectOption'

export default {
    name: 'DialogOncePay',
    props: {
        service: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    emits: ['onComplete'],
    setup(props, { emit }) {
        const isProd = computed(() => process.env.NODE_ENV === 'production')
        const repo = new Repository()

        const $q = useQuasar()
        const { isPayDisabled } = initConfigStore()
        const { selectOption } = userDateSelectOption()

        const myForm = ref(null)

        const prompt = ref(false)

        const clientid = ref('')
        const client_email = ref(null)
        const client_phone = ref(null)
        const pin = ref(null)
        const period = ref('')

        const propsService = ref(props.service)
        const {
            place,
            service_group,
            age_type,
            visiting_form,
            price: originalPrice,
            visiting_form_id,
        } = propsService.value

        const price = computed(() => {
            return `${originalPrice ?? ''} ₽`
        })

        const isDisabled = computed(() => isPayDisabled())

        const onClick = async () => {
            prompt.value = true
        }

        const isValidEmail = (val) => {
            const emailPattern =
                /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
            return emailPattern.test(val) || 'Проверьте введенный email'
        }
        
        const isPeriodValidate = (val) => {
            return !!val || val !== '' || 'Обязательное поле'
        }

        async function validate() {
            return myForm.value.validate()
        }

        const onCard = async () => {
            onSubmit('')
        }

        const onSBP = () => {
            onSubmit('sbp_default')
        }

        const onSubmit = async (type) => {
            let isValidate = await validate()
            if (!isValidate) {
                return
            }
            const year = parseInt(period.value) >= new Date().getMonth() + 1 ? new Date().getFullYear() : new Date().getFullYear() + 1

            let json = {
                cart: [
                    {
                        service_id: props.service.id,
                        quantity: 1,
                        period:
                            period.value === ''
                                ? ''
                                : `${period.value}/${year}`,
                    },
                ],
                clientid: clientid.value,
                client_email: client_email.value,
                client_phone: client_phone.value,
                pstype: type,
                // pin: pin.value,
            }

            let res = await repo.getPayParams(json)
            if (res === 'Не верный PIN') {
                $q.notify({
                    position: 'top-right',
                    color: 'red',
                    textColor: 'white',
                    icon: 'warning',
                    message: res,
                })
            } else {
                emit('onComplete', res)
            }
            prompt.value = false
            clearClient()
        }

        const onTestPay = async () => {
            let isValidate = await validate()
            if (!isValidate) {
                return
            }
            const year = parseInt(period.value) >= new Date().getMonth() + 1 ? new Date().getFullYear() : new Date().getFullYear() + 1
            let json = {
                cart: [
                    {
                        service_id: props.service.id,
                        quantity: 1,
                        period:
                            period.value === ''
                                ? ''
                                : `${period.value}/${year}`,
                    },
                ],
                clientid: clientid.value,
                client_email: client_email.value,
                client_phone: client_phone.value,
                pstype: '',
                pin: pin.value,
            }

            let res = await repo.getTestPayParams(json)
            if (res === 'Не верный PIN') {
                $q.notify({
                    position: 'top-right',
                    color: 'red',
                    textColor: 'white',
                    icon: 'warning',
                    message: res,
                })
            } else {
                emit('onComplete', res)
            }
            prompt.value = false
            clearClient()
        }

        const clearClient = () => {
            clientid.value = null
            client_email.value = null
            client_phone.value = null
            pin.value = null
        }

        return {
            selectOption,
            myForm,
            prompt,
            clientid,
            client_email,
            client_phone,
            pin,
            period,
            isValidEmail,
            onClick,
            onCard,
            onSBP,
            onTestPay,
            isDisabled,
            price,
            place,
            service_group,
            age_type,
            visiting_form,
            visiting_form_id,
            isProd,
            isPeriodValidate
        }
    },
}
</script>

<style scoped>
a:visited {
    color: #0000ee;
}
/*.my-link {*/
/*    text-decoration: none;*/
/*}*/
</style>
